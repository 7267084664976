<template>
  <div>
    <b-form-group id="name-group" label="Title:" label-for="title">
      <b-form-input
        id="name"
        v-model="model.title"
        placeholder="Title"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="type-group" label="First name" label-for="firstname">
      <b-form-input
        id="type"
        v-model="model.firstName"
        placeholder="First name"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="type-group" label="Last name" label-for="lastname">
      <b-form-input
        id="type"
        v-model="model.lastName"
        placeholder="Last name"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
import HelpStore from "@/store/helpstore.js";

export default {
  name: "AuthorNewEdit",
  props: ["author"],
  data() {
    return {
      model: this.author
        ? this.author
        : {
            id: null,
            title: '',
            firstName: '',
            lastName: '',
          },
    };
  },
  mounted() {
    HelpStore.item = this.model;
  },
};
</script>
