<template>
  <div class="reviews">
    <b-container fluid="xxl">
      <b-row class="text-center">
        <b-col>
          <input
            class="px-2 py-1 w-100"
            id="search"
            type="text"
            size="sm"
            placeholder="Search reviews"
            @keyup="getSearchResults()"
            autocomplete="off"
            v-model="query"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <ul v-if="reviews.length" class="results-ul">
            <li v-for="review in reviews" :key="review.id">
              <a href="#" @click="selectReview($event, review)" v-html="review.bibDescription"></a>
            </li>
          </ul>
          <span v-else class="opacity-60">
            <span v-if="query">No results</span>
            <span v-else>Start searching</span>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ReviewAutocomplete',
    props: ['exclude'],
    data() {
      this.$store.state.review.reviews = []
      return {
        query: '',
        timer: null
      }
    },
    mounted() {
      this.$store.state.review.reviews = []
    },
    computed: {
      ...mapState({
        reviews: state => state.review.reviews
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    methods: {
      selectReview(event, review){
        event.preventDefault()
        this.$emit('select', review)
        this.$store.state.review.reviews = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$store.dispatch('searchReviews', {
            query: this.query.toLowerCase(),
            exclude: (this.exclude ? this.exclude : [])
          })
        }, 400)
      }
    }
  }
</script>
