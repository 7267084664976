<template>
  <div class="clearfix">
    <b-table striped small hover :items="editonsData" :per-page="perPage" :current-page="currentPage">
      <template #cell(bibDescription)="data">
        <router-link
          :to="{name: 'ReviewDetails', params: {
            id: data.item.action.id
          }}"
          v-html="data.item.bibDescription"
        >
        </router-link>
      </template>
      <template #cell(commentGeneral)="data">
        <span v-html="data.item.commentGeneral" />
      </template>
      <template #cell(authors)="data">
        <span v-html="data.item.authors" />
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'ReviewDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode"
            @click="editRow($event, data.item.action.review)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" class="float-left" v-if="showTotalLable">
      Total {{ totalRows }} {{ totalRows === 1 ? 'entry' : "entries" }} in table
    </b-col>
    <b-col sm="3" md="2" class="my-1 float-right" v-if="totalRows > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
        first-number
        last-number
      ></b-pagination>
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'ReviewTable',
    props: ['reviews', 'editMode', 'showTotal', 'showLinkReview'],
    data(){
      return {
        currentPage: 1,
        totalRows: this.reviews.length,
        perPage: 20,
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    components: {
      tdColUUID
    },
    methods: {
      deleteRow(event, reviewId){
        event.preventDefault()
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Review deleted`
        })
        this.$emit('delete', reviewId)
      },
      editRow(event, review) {
        event.preventDefault();
        this.$emit("edit", review);
      },
    },
    computed: {
      editonsData(){
        return this.reviews.map((review, index) => {
          review['_index'] = index;
          return {
            id: review.id,
            bibDescription: review.bibDescription,
            ...(this.showLinkReview && { comment: review.comment }),
            commentGeneral: review.commentGeneral,
            authors: review.authors.map(author => author.name).join('; '),
            action: {
              sigleLegamNormalized: review.sigleLegamNormalized,
              id: review.id,
              review: review
            }
          }
        })
      }
    }
  }
</script>
