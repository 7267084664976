<template>
  <div class="authorDetails">
    <div v-if="loading || !author">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="author.firstName + ' ' + author.lastName" :pretitle="'Scholar details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.author.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.author.modify') && config.editable" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="!$store.getters.checkRole('legam.bib.author.modify') && $store.getters.checkRole('legam.bib.author.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Title</h6>
                  <b-form-input
                    id="name"
                    v-model="author.title"
                    placeholder="Title"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="author.title"></span>
                </b-col>
                <b-col>
                  <h6>First name</h6>
                  <b-form-input
                    id="type"
                    v-model="author.firstName"
                    placeholder="First name"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="author.firstName"></span>
                </b-col>
                <b-col>
                  <h6>Last name</h6>
                  <b-form-input
                    id="type"
                    v-model="author.lastName"
                    placeholder="Last name"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="author.lastName"></span>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <h6>Alternative names</h6>
                  <button v-if="editMode" class="btn-legam-xs mb-2 btn-primary" @click="addNewAuthorModal" v-b-modal.modal-add-alt-author>
                    <b-icon icon="lock-fill"></b-icon>
                    Add new
                  </button>
                  <b-modal id="modal-add-alt-author" title="Add alternative author" ok-variant="secondary" v-if="editMode" @ok="addNewAltAuthor" size="lg">
                    <!-- <ManuscriptAutocompleteText :manuscript="modalManuscript" /> -->
                    <AuthorNewEdit :author="modalAuthor" />
                  </b-modal>

                  <b-table striped hover :items="altAuthorsTable">
                    <template #cell(action)="data">
                      <div class="text-right">
                        <a href="#" v-if="editMode" @click="editAltAuthor($event, data.item.action)"><b-icon icon="pencil"></b-icon></a>
                        <a href="#" v-if="editMode" @click="deleteAltAuthor($event, data.item.action)"><b-icon icon="trash"></b-icon></a>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="text-left">
                      Editions <small>({{ author.editions.length }} {{ author.editions.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-edition>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-edition" title="Add edition" ok-only ok-variant="secondary" size="lg">
                        <EditionAutocomplete @select="addEdition" />
                      </b-modal>
                      <EditionTable
                        :showTotal="false"
                        :editions="author.editions"
                        @delete="deleteEdition"
                        :editMode="editMode"
                        v-if="author.editions.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Reviews <small>({{ author.reviews.length }} {{ author.reviews.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-review>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-review" title="Add review" ok-only ok-variant="secondary" size="lg">
                        <ReviewAutocomplete @select="addReview" />
                      </b-modal>
                      <ReviewTable
                        :showTotal="false"
                        :reviews="author.reviews"
                        @delete="deleteReview"
                        :editMode="editMode"
                        v-if="author.studies.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 class="text-left">
                      Studies <small>({{ author.studies.length }} {{ author.studies.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                        <StudyAutocomplete @select="addStudy" />
                      </b-modal>
                      <StudyTable
                        :showTotal="false"
                        :studies="author.studies"
                        @delete="deleteStudy"
                        :editMode="editMode"
                        v-if="author.studies.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'author'" :objectId="id" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import EditionAutocomplete from '@/components/Bibliography/EditionAutocomplete.vue'
  import EditionTable from '@/components/Bibliography/EditionTable.vue'
  import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
  import StudyTable from '@/components/Bibliography/StudyTable.vue'
  import ReviewAutocomplete from '@/components/Bibliography/ReviewAutocomplete.vue'
  import ReviewTable from '@/components/Bibliography/ReviewTable.vue'
  import AuthorNewEdit from "@/components/Bibliography/AuthorNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'AuthorDetails',
    data() {
      return {
        id: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        modalAuthor: null,
        config: config
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      EditionAutocomplete,
      EditionTable,
      StudyAutocomplete,
      StudyTable,
      ReviewAutocomplete,
      ReviewTable,
      AuthorNewEdit,
    },
    computed: {
      ...mapState({
        author: state => state.author.author
      }),
      altAuthorsTable(){
        return this.author.altauthornames.map((altAuthor, index) => {
          altAuthor['_index'] = index;
          return {
            'title': altAuthor.title,
            'firstName': altAuthor.firstName,
            'lastName': altAuthor.lastName,
            'action': altAuthor
          }
        })
      }
    },
    mounted(){
      this.$store.dispatch('loadAuthor', this.id)
    },
    watch: {
      'id'(){
        this.loading = true
        this.$store.dispatch('loadAuthor', this.id)
      },
      'author'(){
        this.loading = false
        console.log("AUTH", this.author)
      }
    },
    methods: {
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadAuthor', this.id)
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Changes dismissed`
        })
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveAuthor', this.author).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteAuthor', this.author).then(() => {
              router.push('/authors')
            })
          }
        })
      },

      addEdition(edition){
        this.$bvModal.hide('modal-add-edition')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Edition added`
        })
        this.author.editions.push(edition)
      },
      deleteEdition(editionId){
        this.author.editions = this.author.editions.filter(
          edition => edition.id != editionId
        )
      },
      addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Study added`
        })
        this.author.studies.push(study)
      },
      deleteStudy(studyId){
        this.author.studies = this.author.studies.filter(
          study => study.id != studyId
        )
      },
      addReview(review){
        this.$bvModal.hide('modal-add-review')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Review added`
        })
        this.author.reviews.push(review)
      },
      deleteReview(reviewId){
        this.author.reviews = this.author.reviews.filter(
          review => review.id != reviewId
        )
      },

      deleteAltAuthor(event, altAuthor) {
        event.preventDefault()
        this.author.altauthornames = this.author.altauthornames.filter(_altAuthor => _altAuthor.id != altAuthor.id)
      },
      editAltAuthor(event, altAuthor) {
        event.preventDefault()
        this.modalAuthor = altAuthor
        this.$bvModal.show('modal-add-alt-author')
      },
      addNewAuthorModal() {
        this.modalAuthor = null
        this.$bvModal.show('modal-add-alt-author')
      },
      addNewAltAuthor (){
        if (HelpStore.item._index != null){
          this.author.altauthornames[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.author.altauthornames.push(HelpStore.item);
        }
      }
    }
  }
</script>
